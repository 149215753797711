import { Component, Prop, Watch } from 'vue-property-decorator';
import Globals from '@/globals';
import PlayerApi from '@/support/playerApi';
import PlayerUtil from '@/support/playerUtil';
import StudioPageBase from '@/support/studioPageBase';
import Util from '@/support/utility';
import { EventBus } from '@/support/eventBus';

@Component
export default class AddNewUserComponent extends StudioPageBase {
    options = {
    };

    key = 'addNewUser';
    permission = Object.assign({}, Globals.NewPermission);
    errors: string[] = [];
    submitDisabled = true;
    dropDownsDisabled = true;
    disabledForSpecialGroup = false;
    busy = false;

    @Prop({ default: <any>'auto' })
    height: string;

    get canBeOwner() {
        return this.permission.provider == 'group' || this.isEmployee;
    }

    get isMicrosoftAccount() {
        return this.permission.provider == 'microsoftaccount';
    }

    get isEmployee() {
        let email = (this.permission.email || "");

        return -1 != email.indexOf('microsoft.com');
    }

    get isGroup() {
        return this.permission.provider == 'group';
    }

    get showEmployeeOrGroup() {
        return this.isEmployee || this.isGroup;
    }

    get showGroup() {
        return !this.permission.provider || this.isGroup;
    }

    get disableWorkEmail() {
        return this.disabledForSpecialGroup || this.isEmployee;
    }

    created() {
        Debug.setDebugModule('AddNewUser', this);

        super.created('addNewUser', true);
    }

    mounted() {
        super.mounted();

        Debug.log('addNewUser mounted');

        this.permission = Object.assign({}, Globals.NewPermission);
        this.busy = false;
    }

    show() {
        this.permission = Object.assign({}, Globals.NewPermission);
        this.busy = false;
        this.submitDisabled = true;
        (<any>this.$modal).show(this.key);
    }

    hide() {
        this.busy = false;
        this.submitDisabled = true;
        this.permission = Object.assign({}, Globals.NewPermission);
        (<any>this.$modal).hide(this.key);
    }

    async submit(event) {
        if (!this.validateInput(event))
            return false;

        this.busy = true;
        await this.addPermission(this.permission);
        this.hide();
    }

    cancel(event) {
        this.hide();
    }

    @Watch('permission.email', { immediate: true })
    onEmailChanged(val: string, oldVal: string) {
        let email = <any>(this.permission.email || "");
        //let name = <any>(this.permission.name || "");

        if (!email) {
            this.updateButtons();
            return;
        }

        if (this.isEmployee) {
            if (!this.showEmployeeOrGroup)
                this.permission.provider = 'aad';
            if (!this.permission.company)
                this.permission.company = 'Microsoft';
            if (!this.permission.workEmail)
                this.permission.workEmail = this.permission.email;
            if (this.permission.provider == 'microsoftaccount')
                this.permission.provider = 'aad';
        } else {
            this.permission.role = 'user';

            if (this.permission.provider == 'group' || this.permission.provider == 'aad')
                this.permission.provider = 'microsoftaccount';
        }

        this.updateButtons();
    }

    @Watch('permission.name', { immediate: true })
    onNameChanged(val: string, oldVal: string) {
        this.updateButtons();
    }

    @Watch('permission.company', { immediate: true })
    onCompanyChanged(val: string, oldVal: string) {
        this.updateButtons();
    }

    updateButtons() {
        this.submitDisabled = !this.isValidInput();
        this.dropDownsDisabled = !Util.isValidEmail(this.permission.email);

        this.disabledForSpecialGroup = this.isSpecialGroup();
    }

    isSpecialGroup() {
        return PlayerUtil.isSpecialGroup(this.permission.name);
    }

    isValidInput() {
        if (!this.permission.name)
            return false;
        else if (this.permission.name != Util.sanitizeCompany(this.permission.name))
            return false;

        if (!this.isSpecialGroup()) {
            if (!this.permission.email)
                return false;
            else if (!Util.isValidEmail(this.permission.email))
                return false;

            if (!this.permission.company)
                return false;
            else if (this.permission.company != Util.sanitizeCompany(this.permission.company))
                return false;

            if (!this.permission.provider)
                return false;
        }

        return true;
    }

    validateInput(event) {
        this.errors.splice(0, this.errors.length);

        if (!this.permission.name)
            this.errors.push('Name required');
        else if (this.permission.name != Util.sanitizeCompany(this.permission.name))
            this.errors.push('Invalid name');

        if (!this.isSpecialGroup()) {
            if (!this.permission.email)
                this.errors.push('Email required');
            else if (!Util.isValidEmail(this.permission.email))
                this.errors.push('Invalid email');

            if (!this.permission.workEmail)
                this.errors.push('Work Email required');
            else if (!Util.isValidEmail(this.permission.workEmail))
                this.errors.push('Invalid work email');

            if (!this.permission.company)
                this.errors.push('Company required');
            else if (this.permission.company != Util.sanitizeCompany(this.permission.company))
                this.errors.push('Invalid company name');

            if (!this.permission.provider)
                this.errors.push('Signin type required');
        }

        if (this.errors.length) {
            event.preventDefault();
            return false;
        }

        return true;
    }

    async addPermission(newUser) {
        try {
            let response = await PlayerApi.addPermissionWorker(this.studio.studio, newUser);

            Util.showToast('Added new user');

            EventBus.Instance.$emit(EventBus.PermissionsChanged, response);

            return response;
        } catch (err) {
            let message = err.message || 'ERROR';
            Util.showError('Failed to add permission for ' + newUser.title + " error: " + message);
            return null;
        }
    }
}
